import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`I'm Ben`}</strong>{` - Welcome to my little corner of the internet.`}</p>
    <p>{`I am always interested is discussing `}<strong parentName="p">{`opportunities as a full stack software engineer!`}</strong>{` Take a look at my `}<a parentName="p" {...{
        "href": "/resume"
      }}>{`resume`}</a>{`, `}<a parentName="p" {...{
        "href": "https://github.com/bweis"
      }}>{`Github`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/bweis96/"
      }}>{`LinkedIn`}</a>{`, and let's `}<a parentName="p" {...{
        "href": "mailto:bweis96@gmail.com"
      }}>{`talk`}</a>{`.`}</p>
    <br />
    <p>{`...`}</p>
    <br />
    <p>{`I realized a while ago that the less time you spend on your personal site,
the happier you will be.`}</p>
    <br />
    <p>{`So here is an ascii turtle.`}</p>
    <pre>
  {"  _____     ___\n" + " /        |  o |\n" + "|        |/ ___|\n" + "|_________/\n" + "|_|_| |_|_|\n"}
    </pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      